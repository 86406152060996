<template>
    <div>
        <page-header
            :title="$t('pages.accountDetails.AccountDetails')"
            show-back
            @onClose="returnToRoot"
        />
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <list-row
            v-if="rescissionInfo.isInRescissionPeriod"
            :title="$t('pages.accountDetails.rescissionPeriodEnd')"
            :value="endRescissionStr"
        />
        <list-row
            :title="$t('pages.accountDetails.PersonalDetails')"
            show-arrow
            @onClick="$router.push('PersonalDetails')"
        />
        <list-row
            v-show="!accountIsClosed"
            :title="$t('pages.accountDetails.PaymentSources')"
            show-arrow
            @onClick="$router.push('paymentSource')"
        />
        <list-row
            :title="$t('pages.accountDetails.NotificationSettings')"
            show-arrow
            @onClick="$router.push('notificationSetting')"
        />
        <section-header>{{ $t('pages.accountDetails.CardTerms') }}</section-header>
        <list-row
            v-if="showPrimeOption && !accountIsClosed"
            :title="$t('pages.accountDetails.AvenPrimeSettings')"
            show-arrow
            :value="isPrimeEnabledText"
            value-class="text-muted"
            @onClick="$router.push('avenPrimeSetting')"
        />
        <list-row
            :title="$t('pages.accountDetails.CreditLimit')"
            :value="toFormattedUSD(creditLimit)"
            value-class="text-muted"
        />
        <list-row
            :title="$t('pages.accountDetails.AvailableCredit')"
            :value="toFormattedUSD(availableCredit)"
            value-class="text-muted"
        />
        <list-row
            :title="$t('pages.accountDetails.UnlimitedCashback')"
            :value="toFormattedAprStripTrailingZerosAfterDecimalPoint(dollarToCashbackRewardPointsConversion)"
            value-class="text-muted"
        />
        <list-row
            :title="$t('pages.accountDetails.CurrentAPR')"
            :value="formatApr(apr / 100)"
            value-class="text-muted"
        />
        <list-row
            :title="$t('pages.accountDetails.CashOutFee')"
            :value="toFormattedAprStripTrailingZerosAfterDecimalPoint(cashOutTotalAmountWithFeeMultiplier - 1)"
            value-class="text-muted"
        />
        <list-row
            :title="$t('pages.accountDetails.BalanceTransferFee')"
            :value="toFormattedAprStripTrailingZerosAfterDecimalPoint(balanceTransferFee)"
            value-class="text-muted"
        />
        <list-row
            v-if="monthlyFee"
            :title="$t('pages.accountDetails.MonthlyFee')"
            :value="toFormattedUSD(monthlyFee)"
            value-class="text-muted"
        />
        <section-header>{{ $t('pages.accountDetails.PolicyAndAgreement') }}</section-header>
        <list-row
            :title="$t('pages.accountDetails.AccountAgreement')"
            :submitting="downloadingAccountAgreement"
            @onClick="getDocument('AccountAgreement')"
            show-arrow
        />
        <list-row
            :title="$t('pages.accountDetails.PrivacyPolicy')"
            link-to="https://aven.com/privacy"
        />
        <list-row
            :title="$t('pages.accountDetails.TermsAndConditions')"
            link-to="https://aven.com/docs/TermsOfUse.pdf"
        />
        <list-row
            :title="$t('pages.accountDetails.Disclosures')"
            link-to="https://aven.com/disclosures"
        />
    </div>
</template>

<script>
    import ListRow from '@/components/ListRow'
    import PageHeader from '@/components/PageHeader'
    import SectionHeader from '@/components/SectionHeader'
    import { mapGetters } from 'vuex'
    import format from '@/mixins/format'
    import { logger } from '@/utils/logger'
    import { getLegalDocumentDownload } from '@/services/api'
    import { isSafari } from '@/utils/parseUserAgents'
    import { ProductCategory } from '@/store'
    import generic from '@/utils/generic'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: 'AccountDetails',
        components: {
            SectionHeader,
            PageHeader,
            ListRow,
        },
        mixins: [format, navigation],
        data: function () {
            return {
                loading: false,
                downloadingAccountAgreement: false,
            }
        },
        computed: {
            ...mapGetters([
                'creditLimit',
                'availableCredit',
                'apr',
                'balanceTransferFee',
                'isWebView',
                'monthlyFee',
                'nextStatementDate',
                'productCategory',
                'desiredProductCategory',
                'showPrimeOption',
                'cashOutTotalAmountWithFeeMultiplier',
                'dollarToCashbackRewardPointsConversion',
                'accountIsClosed',
                'rescissionInfo',
            ]),
            isPrimeEnabledText: function () {
                const statementDateText = generic.utcToTimeZone(this.nextStatementDate, undefined, 'MMM D')
                if (this.desiredProductCategory === ProductCategory.prime) {
                    if (this.productCategory !== ProductCategory.prime) {
                        return `On (Starting ${statementDateText})`
                    } else {
                        return 'On'
                    }
                } else {
                    if (this.productCategory === ProductCategory.prime) {
                        return `Off (Ending ${statementDateText})`
                    } else {
                        return 'Off'
                    }
                }
            },
            endRescissionStr() {
                return generic.convertDateTimeFormat(this.rescissionInfo.endRescission, { outputFormat: 'MMM DD' })
            },
        },
        methods: {
            getDocument: async function (docType) {
                if (this.isWebView) {
                    logger.info(`native webview, get Account Agreement`)
                    const link = `/legal/document?docType=${docType}`
                    logger.info(`native webview, set window.location.href to ${link}`)
                    window.location.href = link
                } else {
                    logger.info(`browser, download account agreement`)
                    this.downloadingAccountAgreement = true
                    const response = await getLegalDocumentDownload(docType)
                    logger.info(`browser, download account agreement completed`)
                    const file = new Blob([response.data], { type: 'application/pdf' })
                    const fileURL = URL.createObjectURL(file)
                    if (isSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                    this.downloadingAccountAgreement = false
                }
            },
        },
    }
</script>

<style scoped></style>
